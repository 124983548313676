import Header from "../../components/NavMenu/Header";
import Hero from "../../components/Hero/Hero";
import SectionB from "./SectionB";
import SectionD from "./SectionD";
import SectionC from "./SectionC";
import Card from "./Card/Card";
import { Grid, Box } from "@mui/material";
import Footer from "components/Footer";

const Index = () => {
  return (
    <>
      <Header />
      <Hero />
      {/* <SectionA /> */}
      <SectionB />
      <SectionC />
      <SectionD />

      {/* //Card */}
      <Box width={{ xs: "90%", md: "75%" }} margin="40px auto">
        <Grid container spacing={3}>
          {cardData.map((item, index) => {
            return (
              <Grid key={index} item xs={12} md={6} lg={4}>
                <Card title={item.title} img={item.img} link={item.link} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default Index;

const cardData = [
  {
    title: "Manage Your School with Clique SMS",
    img: "https://cliqueng.imgix.net/asset/manage-your-school-with-clique.jpg?w=500",
    link: "https://sms.clique.ng",
  },
  {
    title: "Run an Online School with Clique LMS",
    img: "https://cliqueng.imgix.net/asset/what-we-offer.jpg?w=500",
    link: "https://lms.clique.ng",
  },
  {
    title: "Learn Coding and Software Engineering",
    img: "https://cliqueng.imgix.net/asset/learn-coding.jpg?w=500",
    link: "https://ebstechschool.com",
  },
  {
    title: "Become an Online or Home Tutor with Clique",
    img: "https://cliqueng.imgix.net/asset/become-an-online-or-home-tutor-with-clique.jpg?w=500",
    link: "https://marketplace.clique.ng",
  },
  {
    title: "Take Prep Test with Clique CBT",
    img: "https://cliqueng.imgix.net/asset/take-prep-test-with-clique.jpg?w=500",
    link: "https://cbt.clique.ng",
  },
  {
    title: "Book Online or Home Tutor",
    img: "https://cliqueng.imgix.net/asset/book-Online-home-tutor.jpg?w=500",
    link: "https://marketplace.clique.ng",
  },
];
