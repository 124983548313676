import { Desktop } from "components/Breakpoints/BreakPoints";
import styled from "styled-components";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Buttons } from "../../../components/Button/Buttons";
import { Box } from "@mui/material";
const CardContainer = styled("div")`
  background: #f2f8fa;
  height: 420px;
  border-radius: 15px;
  min-width: 310px;
  scroll-snap-align: start;
  transition: all 0.2s;

  ${Desktop({
    maxWidth: "320px",
  })}
`;

const CardContent = styled("div")`
  width: 100%;
`;

const CardImg = styled("div")`
  width: 100%;
`;

const Img = styled("img")`
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
`;

const CardText = styled("div")`
  width: 90%;
  padding: 20px 16px;
`;

const Title = styled("h2")`
  font-size: 20px;
  font-weight: 600;
  color: var(--primary);
`;

const Card = ({ img, title, link }) => {
  const openLink = (card_link) => window.open(card_link, "_blank");
  return (
    <CardContainer>
      <CardContent>
        <CardImg>
          <Img src={img} alt="img" />
        </CardImg>

        <CardText>
          <Title>{title}</Title>
          <Box mt={3}>
            <Buttons
              sx={{ padding: "0 !important" }}
              onClick={() => openLink(link)}
              btnVariant="btn-text"
            >
              GET STARTED <ArrowForwardIcon />
            </Buttons>
          </Box>
        </CardText>
      </CardContent>
    </CardContainer>
  );
};

export default Card;
