import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import styled from "styled-components";
import { Desktop } from "components/Breakpoints/BreakPoints";
import { Buttons } from "components/Button/Buttons";

const TabContentWrap = styled("div")`
  padding: 40px 0;
`;

const FlexContainer = styled("div")`
  @media screen and (min-width: 960px) {
    display: flex;
    justify-content: center;
    gap: 5rem;
  } ;
`;

const FlexColumn = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 540px) {
  }
  ${Desktop({
    maxWidth: "100%",
    flexBasis: "100%",
  })}
`;

const Title = styled("h2")`
  line-height: 1.1;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
  color: var(--primary);

  ${Desktop({
    fontSize: "2.5rem",
    marginBottom: "2rem",
  })};
`;

const Text = styled("p")`
  max-width: 440px;
  margin: 1rem 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
  color: var(--primary);
  margin-bottom: 10px;
  letter-spacing: 1px;
`;

const ListContent = styled("ul")`
  text-overflow: wrap;
  font-weight: 600;
  font-size: 1.1rem;
  margin: 20px 0;
  color: var(--primary);
  ${Desktop({
    marginBottom: "30px",
  })};
`;

const List = styled("li")`
  display: flex;
  align-items: center;
  margin-top: 10px;

  .MuiSvgIcon-root {
    margin-right: 10px;
    color: var(--secondary);
  }

  ${Desktop({
    marginTop: "20px",
  })};
`;

const ImageWrap = styled("div")`
  margin: 40px 0;

  @media screen and (min-width: 540px) {
    width: 85%;
  }

  ${Desktop({
    width: "100%",
    margin: "0",
  })}
`;

const Img = styled("img")`
  width: 100%;
  border-radius: 10px;
`;

const TabContent = ({ title, desc, text1, text2, text3, text4, img }) => {
  return (
    <TabContentWrap>
      <FlexContainer>
        <FlexColumn>
          <Title>{title}</Title>
          <Text>{desc}</Text>

          <ListContent>
            <List>
              <TaskAltIcon /> {text1}
            </List>
            <List>
              <TaskAltIcon /> {text2}
            </List>

            <List>
              <TaskAltIcon /> {text3}
            </List>
            <List>
              <TaskAltIcon /> {text4}
            </List>
          </ListContent>

          <Buttons
            onClick={() => window.open("https://sms.clique.ng", "_blank")}
            btnVariant="btn-secondary shadow"
            btnSize="btn-large"
          >
            LEARN ABOUT THE PLATFORM <ArrowForwardIcon />
          </Buttons>
        </FlexColumn>

        <ImageWrap>
          <Img src={img} alt="banner" />
        </ImageWrap>
      </FlexContainer>
    </TabContentWrap>
  );
};

export default TabContent;
