import { Desktop } from "components/Breakpoints/BreakPoints";
import styled from "styled-components";

const DropDownContainer = styled("div")`
  border-radius: 0 0 10px 10px;
  width: 250px;
  z-index: 1000;

  ${Desktop({
    position: "absolute",
    transform: "translateX(-50%)",
    top: "calc(100% + 5px)",
    background: "#fffeff",
    boxShadow: "0 0 30px 10px rgb(20 61 80 / 7%)",
  })}
`;

const Container = styled("div")`
  display: flex;
  gap: 2rem;
`;

const Flex = styled("div")`
  padding: 15px 20px 0px 20px;

  ${Desktop({
    padding: "12px 20px",
  })}
`;
const Img = styled("img")`
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin-right: 10px;
`;

const MenuLinks = styled("div")``;

const Links = styled("div")`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  a {
    font-size: 15px;
    color: #143d50;
    font-weight: 700;
  }

  a:hover {
    color: var(--secondary);
  }
`;

export const DropDown = () => {
  return (
    <DropDownContainer className="dropDown">
      <Container>
        <Flex>
          <MenuLinks>
            <Links>
              <a href="https://sms.clique.ng/login">SMS</a>
            </Links>
            <Links>
            <a href="https://lms.clique.ng/login">LMS</a>
            </Links>
            <Links>
            <a href="https://cbt.clique.ng/signin">CBT</a>
            </Links>
            <Links>
            <a href="https://ebstechschool.com/login">VIRTUAL ACADEMY</a>
            </Links>
            <Links>
            <a href="https://marketplace.clique.ng/login">BOOK TUTORS</a>
            </Links>
           
          </MenuLinks>
        </Flex>
      </Container>
    </DropDownContainer>
  );
};
