import styled from "styled-components";
import { Desktop } from "../Breakpoints/BreakPoints";
import SubHeader from "./SubHeader";
import TopHeader from "./TopHeader";

const Wrapper = styled("div")`
  width: 100%;
  height: auto;
  padding: 15px 0;
  background: #fff;
  box-shadow: 0 0 30px 10px rgb(20 61 80 / 7%);
  position: sticky;
  top: 0;
  z-index: 1;

  .top-nav {
    display: none;
  }

  :only-child {
    padding: 15px;
  }

  ${Desktop({
    padding: "10px 0px",

    ".top-nav": {
      display: "flex",
    },
  })}
`;

const HR = styled("hr")`
  display: none;

  ${Desktop({
    display: "flex",
    marginTop: "15px",
    backgroundColor: "#e4e4e4",
    border: "none",
    height: "1px",
  })}
`;
const Header = () => {
  return (
    <Wrapper>
      {/* <div className="top-nav">
        <TopHeader />
      </div>

      <HR /> */}

      <SubHeader />
    </Wrapper>
  );
};

export default Header;
