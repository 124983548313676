import styled from "styled-components";
import { Desktop } from "../Breakpoints/BreakPoints";

const HeaderWrap = styled("div")`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Desktop({
    width: "90%",
    margin: "5px auto",
  })}
`;

const Location = styled("div")`
  display: none;

  ${Desktop({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "var(--bgColor)",
    borderRadius: "13px",
    height: "25px",
  })}

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    color: var(--primary);
    font-weight: 600;
    font-size: 12px;
  }

  .MuiSvgIcon-root {
    font-weight: normal;
    font-size: 15px;

    color: var(--secondary);
  }

  a:hover {
    color: var(--secondary);
  }
`;

const SubMenu = styled("div")`
  ${Desktop({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
  })}
`;

const SearchBox = styled("form")`
  display: none;

  ${Desktop({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "var(--bgColor)",
    borderRadius: "25px",
    height: "25px",
    gap: "20px",
    width: "250px",
    padding: "5px 10px",
  })}

  .MuiSvgIcon-root {
    font-weight: 600;
    color: var(--secondary);
  }
`;

const SearchInput = styled("input")`
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  background: var(--bgColor);
  font-size: 15px;
  color: var(--primary);
  font-weight: 600;

  ::placeholder {
    color: var(--primary);
    font-weight: 600;
    padding-left: 5px;
  }
`;

const Menus = styled("div")`
  padding: 0 10px;
  margin-bottom: 10px;
  text-align: left;

  ${Desktop({
    padding: "0",
    margin: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "30px",
  })}
`;

const MenuItems = styled("li")`
  margin-top: 10px;
  a {
    color: var(--primary);
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.2;
  }
  a:hover {
    text-decoration: underline;
  }
  ${Desktop({
    margin: "0",
    "& a": {
      fontSize: "0.9rem",
    },
  })}
`;


const TopHeader = () => {


  return (
    <HeaderWrap>
      {/* <Location>
        <a href="#" onClick={() => setOpenModal(!openModal)}>
          North Ameria
          <KeyboardArrowDownIcon />
        </a>

        {openModal && <Lang closeModal={setOpenModal} />}
      </Location> */}

      <SubMenu>
       
        <Menus>
          {/* <MenuItems>
            <a href="#">Try canvas Free</a>
          </MenuItems>
          <MenuItems>
            <a href="#">Corp Edu</a>
          </MenuItems>
          <MenuItems>
            <a href="#">Support</a>
          </MenuItems>
          <MenuItems>
            <a href="#">Contact Us</a>
          </MenuItems> */}
        </Menus>
      </SubMenu>
    </HeaderWrap>
  );
};

export default TopHeader;
