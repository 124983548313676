import { Button } from "@mui/material";
import styled from "styled-components";

const sizes = {
  small: "0px",
  medium: "768px",
  large: "1200px",
};

export const devices = {
  small: `(min-width: ${sizes.small})`,
  medium: `(min-width: ${sizes.medium})`,
  large: `(min-width: ${sizes.large})`,
};

export const Link = styled.div`
  color: #647aa8;
`;

export const SubmitButton = styled(Button)`
  && {
    border-radius: 50rem;
    padding: 12px 22px;
    font-weight: 600;
    min-width: 140px;
  }
`;

export const TextFieldContainer = styled.div<{ direction?: "row" | "column" }>`
  margin-top: 24px;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  flex-direction: ${(props) => props.direction || "column"};
`;

export const Title = styled.h1`
  font-size: 1.75rem;
  font-weight: 700;
  @media ${devices.medium} {
    text-align: center;
  }
`;

export const Paragraph = styled.p`
  font-size: 14;
  font-weight: normal;
  @media ${devices.medium} {
    text-align: center;
  }
`;

export const Layout = styled.div`
  display: flex;
`;

export const FormContent = styled.div`
  max-width: 500px;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
`;

export const FormContainer = styled.div`
  width: 100%;
  padding: 30px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
`;

export const Banner = styled.div`
  display: none;
  margin-top: 64px;
  background: #f5f6fa;
  @media screen and (min-width: 992px) {
    display: flex;
    flex: 0 0 50%;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
`;
