import { css } from "styled-components"; 
// RESPONSIVE MOBILE STYLE

export const Desktop = (props) =>{
    return css`
    @media only screen and (min-width: 960px) {
    ${props}
    
}
    `
}

