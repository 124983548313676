import * as React from "react";
import { Desktop } from "components/Breakpoints/BreakPoints";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContent from "./TabContent";
import { Tab1, Tab2, Tab3 } from "./ComponentProps";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import HearingIcon from "@mui/icons-material/Hearing";
import SsidChartIcon from "@mui/icons-material/SsidChart";


const SectionContainer = styled("div")`
  padding: 20px 0;
  background-color: var(--bgColor);
`;

const Container = styled("div")`
  width: 90%;
  margin: 0 auto;

  ${Desktop({
    width: "75%",
  })};
`;

const TextBox = styled("div")`
  padding: 30px 0;

  h3 {
    font-size: 1rem;
    font-weight: 600;
    color: var(--primary);
  }

  ${Desktop({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "50px 0",
  })};
`;

const Title = styled("h2")`
  font-size: 2rem;
  font-weight: 600;
  color: var(--primary);

  ${Desktop({
    fontSize: "2.5rem",
  })};
`;

const Text = styled("p")`
  font-size: 1rem;
  font-weight: 400;
  color: var(--primary);

  ${Desktop({
    width: "450px",
    textAlign: "center",
  })};
`;

const TabWrap = styled(TabPanel)`
  padding: 0;
`;

const TabStyle = styled(TabList)({
  "& .MuiTabs-indicator": {
    backgroundColor: "var(--secondary)",
    height: "0.5rem",
  },
});

const Tabs = styled((props) => <Tab {...props} />)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "1.1rem",
  marginRight: theme.spacing(1),
  color: "var(--primary)",

  margin: "0.5rem auto",
  gap: "0.6rem",
  textAlign: "center",

  "&:hover": {
    color: "var(--secondary)",

    opacity: 1,
  },

  "&.Mui-selected": {
    color: "var(--secondary)",

    fontWeight: 700,
  },

  "&.Mui-focusVisible": {
    backgroundColor: "#d1eaff",
  },
}));

const SectionC = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  return (
    <SectionContainer>
      <Container>
        <TextBox>
        <Title>WHAT WE OFFER</Title>
          <h3 style={{ marginBottom: "1rem" }}>DIGITAL SOLUTION FOR TEACHING AND LEARNING</h3>

          <Text>
           Clique helps educators at all levels to deliver world-class learning, monetize contents, and run an online school
           with ease. Our everyday learning solution is a game changer for learners education journey.
          </Text>
        </TextBox>

        {/* TAB COMPONENT */}

        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabStyle
                centered
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="simplify teaching"
              >
                <Tabs
                  label="Simplify Teaching"
                  icon={
                    <ConnectWithoutContactIcon sx={{ fontSize: "2.5rem" }} />
                  }
                  iconPosition="start"
                  value="1"
                />
                <Tabs
                  label="Learning with fun"
                  icon={<SsidChartIcon sx={{ fontSize: "2.5rem" }} />}
                  iconPosition="start"
                  value="2"
                />
                <Tabs
                  label="Amplify Impact"
                  icon={<HearingIcon sx={{ fontSize: "2.5rem" }} />}
                  iconPosition="start"
                  value="3"
                />
              </TabStyle>
            </Box>
            <TabWrap value="1" sx={{ padding: "10px " }}>
              <TabContent {...Tab1} />
            </TabWrap>
            <TabWrap value="2">
              <TabContent {...Tab2} />
            </TabWrap>
            <TabWrap value="3">
              <TabContent {...Tab3} />
            </TabWrap>
          </TabContext>
        </Box>
      </Container>
    </SectionContainer>
  );
};

export default SectionC;
