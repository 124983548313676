import { useEffect, useState } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import styled from "styled-components";

const Scroll = styled("div")`
  position: fixed;
  bottom: 80px;
  right: 30px;
  z-index: 99000;
  cursor: pointer;

  span {
    writing-mode: vertical-lr;
    text-transform: uppercase;
    transform: rotate(180deg);
    font-size: 1rem;
    color: var(--secondary);
    font-weight: 700;
    padding-top: 7px;
    padding-right: 7px;
  }

  :hover span, button {
    color: #ff4400;
  }

  :hover button {
    background-color: #ff4400;
  }

  @media screen and (max-width: 960px) {
    display: none;
  }
`;
const Button = styled("button")`
  border: none;
  outline: none;
  width: 40px;
  height: 40px;
  background: var(--secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 100%;
  cursor: pointer;

  .MuiSvgIcon-root {
    color: #fff;
  }


`;
const ScrollToTop = () => {
  const [showTopBtn, setShowTopBtn] = useState();

  useEffect(() => {
    const listenToScroll = () => {
      if (window.scrollY > 700) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    };

    window.addEventListener("scroll", listenToScroll);

    return () => {
      window.removeEventListener("onScroll", listenToScroll);
    };
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {showTopBtn && (
        <Scroll id="scrollbtn">
          <span>scroll to top</span>

          <Button onClick={goToTop}>
            <ArrowUpwardIcon />
          </Button>
        </Scroll>
      )}
    </>
  );
};

export default ScrollToTop;
