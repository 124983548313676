import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Desktop } from "../../components/Breakpoints/BreakPoints";
import styled from "styled-components";
import { Buttons } from "components/Button/Buttons";
import { useHistory } from "react-router-dom";

const FlexContainer = styled("div")`
  padding: 40px 0;

  ${Desktop({
    padding: "30px 0",
  })}
`;

const Container = styled("div")`
  width: 90%;
  margin: 0 auto;

  ${Desktop({
    width: "75%",
  })}
`;

const FlexRow = styled("div")`
  @media screen and (min-width: 760px) {
    display: flex;
    justify-content: center;
    gap: 5rem;
  } ;
`;

const FlexColumn = styled("div")`
  ${Desktop({
    maxWidth: "100%",
    flexBasis: "100%",
  })}
`;

const ImageWrap = styled("div")`
  margin-bottom: 50px;

  @media screen and (min-width: 540px) {
    width: 85%;
  }

  ${Desktop({
    width: "100%",
  })}
`;

const Img = styled("img")`
  width: 100%;
  border-radius: 50%;
`;

const TextWrapper = styled("div")`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
  }

  h3 {
    line-height: 1.1;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 0.8rem;
    color: var(--primary);
  }
`;

const Title = styled("h2")`
  line-height: 1.1;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.8rem;
  color: var(--primary);

  ${Desktop({
    fontSize: "2.5rem",
    textAlign: "center",
    marginBottom: "4rem",
    maxWidth: "600px",
    alignSelf: "center",
  })};
`;

const Text = styled("p")`
  max-width: 440px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
  color: var(--primary);
  margin-bottom: 10px;
  letter-spacing: 1px;
`;

const TitleWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SectionB = () => {
  const history = useHistory();
  return (
    <FlexContainer
      style={{ background: "#fff", marginBottom: "0.5rem", marginTop: "2rem" }}
    >
      <Container>
        <TitleWrapper>
          <Title style={{ fontSize: "1rem", marginBottom: "2rem" }}>
            INNOVATION FOR EVERY LEVELS OF LEARNING
          </Title>
          <Title className="title">
            Clique is build for educators and learners at all levels
          </Title>
        </TitleWrapper>

        <FlexRow>
          <FlexColumn style={{ marginBottom: "30px" }}>
            <ImageWrap style={{ marginBottom: "15px" }}>
              <Img
                src="https://cliqueng.imgix.net/asset/k12.jpg?w=300"
                alt="K12"
              />
            </ImageWrap>
            <TextWrapper>
              <h3>K-12</h3>
              <Text>
                Teach lifelong skills to K-12 students. Manage academic progress
                and administrative task, extend learning beyound the classroom,
                and deliver coding classes to students.
              </Text>

              <Buttons
                onClick={() => window.open("https://sms.clique.ng", "_blank")}
                btnVariant="btn-text"
              >
                Learn More <ArrowForwardIcon />
              </Buttons>
            </TextWrapper>
          </FlexColumn>

          <FlexColumn style={{ marginBottom: "30px" }}>
            <ImageWrap style={{ marginBottom: "15px" }}>
              <Img
                src="https://cliqueng.imgix.net/asset/college-university.jpg?w=300"
                alt="College and University"
              />
            </ImageWrap>
            <TextWrapper>
              <h3>College & University</h3>
              <Text>
                Streamline content delivery, assessment records, host live
                classes, and perform administrative activities with ease.
              </Text>

              <Buttons
                onClick={() => window.open("https://lms.clique.ng", "_blank")}
                btnVariant="btn-text"
              >
                Learn More <ArrowForwardIcon />
              </Buttons>
            </TextWrapper>
          </FlexColumn>

          <FlexColumn>
            <ImageWrap style={{ marginBottom: "15px" }}>
              <Img
                src="https://cliqueng.imgix.net/asset/lifelong-learning.jpg?w=300"
                alt="Lifelong Learning"
              />
            </ImageWrap>
            <TextWrapper>
              <h3>Lifelong learning</h3>
              <Text>
                Monetize content, run an online school, and deliver one-on-one
                coaching without hassle. Clique provide the tools to build and
                reach students.
              </Text>

              <Buttons
                onClick={() => window.open("https://lms.clique.ng", "_blank")}
                btnVariant="btn-text"
              >
                Learn More <ArrowForwardIcon />
              </Buttons>
            </TextWrapper>
          </FlexColumn>
        </FlexRow>
      </Container>
    </FlexContainer>
  );
};

export default SectionB;
