export const Tab1 ={
    title: "Simplify Teaching",
    desc:"Our solution provide great support for teachers at all level to deliver world-class learning with ease. This enables schools to amplify their impact and student to gain outstanding outcome in their education journey.",
    text1:'Simplicity, flexibility, and ease of use', 
    text2:'Professional development at scale', 
    text3:'Monetize content and run an online school', 
    text4:'Support for all form of teaching and learning',
    img: 'https://cliqueng.imgix.net/asset/simplify-teaching-3x4.jpg?w=500',

    
}

export const Tab2 ={
    title: "Learning with fun",
    desc:"Student experience world-class tireless learning, collaborate with other learners, and commit to learning from anywhere and anytime.",
    text1:'Access course content and participate in deep interaction from anywhere', 
    text2:'Eradicate disadvantage and present equal oppourtunity and mobility for students, teachers, and parents', 
    text3:'Flexible learning, assessment, and pathways', 
    text4:'Session based and self paced video learning.',
    img: 'https://cliqueng.imgix.net/asset/learning-with-fun-3x4.jpg?w=500',

    
}

export const Tab3 ={
    title: "Intensify Impact",
    desc:"Deliver world-class learning to students, manage academic activities, streamline content sharing, and teach lifelong skills to learners. Use clique to monetize contents and run an online school with zero upfront cost.",
    text1:'Integrated tools for a cohesive digital classroom', 
    text2:'Self-service reports around engagement, performance, and course activity', 
    text3:'Guaranteed security and uptime', 
    text4:'Digital tools that scale for any size and type of institution',
    img: 'https://cliqueng.imgix.net/asset/intensify-impact-3x4.jpg?w=500'  
}