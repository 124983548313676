import jwt_decode from "jwt-decode";

export function setCookie(name: string, value: string, days: number) {
  var expires;
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  } else {
    expires = "";
  }
  document.cookie = name + "=" + value + expires + "; path=/";
}

export function getCookie(c_name: string) {
  let name = c_name + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      // remove all the empty whitespaces, till no more
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function setToken(token: string) {
  setCookie("token", token, 7);
}

export function getToken() {
  return getCookie("token") || "";
}

export function clearAuthCookies() {
  setToken("");
}

interface Itoken {
  userid: string;
  school: string;
  role: string;
  privileges: string;
  email: string;
}

export function decodeToken(): Itoken {
  try {
    const token = getToken();
    const decoded: Itoken = jwt_decode(token);
    return decoded;
  } catch {
    return {} as Itoken;
  }
}
