import styled from "styled-components";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Desktop } from "components/Breakpoints/BreakPoints";
import { Buttons } from "components/Button/Buttons";

export const SectionWrapper = styled("div")`
  padding: 90px 0;
  background-color: var(--primary);

  ${Desktop({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "120px 0",
  })};
`;

const Container = styled("div")`
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled("h1")`
  line-height: 1.1;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
  color: #fff;

  ${Desktop({
    fontSize: "2.5rem",
  })};
`;

const SectionD = () => {
  return (
    <SectionWrapper>
      <Container>
        <Title>Ready to explore Clique?</Title>
        <Buttons
          onClick={() => window.open("https://sms.clique.ng", "_blank")}
          type="button"
          btnVariant="btn-tertiary"
          btnSize="btn-large"
        >
          LET'S GET STARTED
          <ArrowForwardIcon />
        </Buttons>
      </Container>
    </SectionWrapper>
  );
};

export default SectionD;
