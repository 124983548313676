import React from "react";
import { Box, Grid, Link } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import styled from "styled-components";
import { Link as RouterLink, useLocation } from "react-router-dom";
import CallIcon from "@mui/icons-material/Call";
import { Paragraph } from "views/auth/Common";

const HR = styled("hr")`
  border: 1px solid #85888d;
  opacity: 0.4;
`;

const Footer = () => {
  const { contacts, products, about, companies, resources } = footerData;
  return (
    <Box
      sx={{ background: "var(--bgColor)", color: "#fff !important" }}
      pt={4}
      pb={4}
    >
      <Box sx={{ width: "90%", margin: "0 auto" }}>
        <Grid
          container
          justifyContent="space-between"
          rowGap={{ xs: 4, sm: 4 }}
          columnGap={2}
          sx={{ ".css-1y0kxma-MuiGrid-root": { maxWidth: "45%" } }}
        >
          <Grid xs={12} sm={6} md="auto">
            <Box width={{ xs: "80px", md: "100px" }}>
              <RouterLink to="/">
                <img src="/logo.png" alt="brand logo" />
              </RouterLink>
            </Box>
            <Box
              className="flex"
              flexDirection="column"
              gap="16px"
              mt={1}
              mb={2}
            >
              {contacts.map((contact, index) => {
                return (
                  <Link href={contact.link} target="_blank" key={index}>
                    <Box className="flex align-center" gap="12px">
                      <Box sx={iconStyle}>
                        <contact.icon />
                      </Box>
                      <Paragraph style={{ color: "#85888d" }}>
                        {contact.title}
                      </Paragraph>
                    </Box>
                  </Link>
                );
              })}
            </Box>
          </Grid>

          <Grid xs={6} md="auto">
            <Box sx={itemStyle} margin={{ xs: "0", md: "0 auto" }}>
              <Title>Products</Title>
              {products.map((product, index) => {
                return (
                  <Links key={index} href={product.link} target="_blank">
                    {product.title}
                  </Links>
                );
              })}
            </Box>
          </Grid>

          <Grid xs={6} md="auto">
            <Box sx={itemStyle} margin={{ xs: "0", md: "0 auto" }}>
              <Title>About Us</Title>
              {about.map((item, index) => {
                return (
                  <Links key={index} href={item.link}>
                    {item.title}
                  </Links>
                );
              })}
            </Box>
          </Grid>

          <Grid xs={6} md="auto">
            <Box sx={itemStyle} margin={{ xs: "0", md: "0 0 0 auto" }}>
              <Title>Companies</Title>
              {companies.map((company, index) => {
                return (
                  <Links key={index} href={company.link}>
                    {company.title}
                  </Links>
                );
              })}
            </Box>
          </Grid>

          <Grid xs={6} md="auto">
            <Box sx={itemStyle} margin={{ xs: "0", md: "0 auto" }}>
              <Title>Resource</Title>
              <RouterLink to="/faq">FAQs</RouterLink>
              {resources.map((resource, index) => {
                return (
                  <Links key={index} href={resource.link}>
                    {resource.title}
                  </Links>
                );
              })}
            </Box>
          </Grid>
        </Grid>
        <Box mt={2} mb={5}>
          <p style={{ color: "#85888d" }}>© 2023 Clique All rights reserved</p>
        </Box>

        <HR />

        <Box mt={5} maxWidth="100%" textAlign="left">
          <Box mb={2}>
            <Paragraph style={{ color: "var(--primary)", textAlign: "left" }}>
              Clique is a registered trademark of EBS Academy. ©
              2023 EBS Academy.
            </Paragraph>
          </Box>

        </Box>
      </Box>
    </Box>
  );
};

export default Footer;

const footerData = {
  contacts: [
    {
      title: "+234 7059 652 010",
      link: "tel:+2347059652010",
      icon: CallIcon,
    },
    {
      icon: TwitterIcon,
      url: "https://twitter.com/ebs_academy",
      title: "Twitter",
    },
    {
      icon: InstagramIcon,
      url: "https://www.instagram.com/ebsacademy/",
      title: "Instagram",
    },
    {
      icon: FacebookIcon,
      url: "https://facebook.com/ebstechschool",
      title: "Facebook",
    },
    {
      icon: LinkedInIcon,
      url: "https://www.linkedin.com/company/ebsacademy/",
      title: "LinkedIn",
    },
  ],
  products: [
    {
      title: "Clique SMS",
      link: "https://sms.clique.ng/index",
    },
    {
      title: "Clique LMS",
      link: "https://lms.clique.ng/",
    },
    {
      title: "Clique CBT",
      link: "https://cbt.clique.ng/signin",
    },
    {
      title: "Clique Market Place",
      link: "https://marketplace.clique.ng/",
    },
    {
      title: "EBS Code Academy",
      link: "https://ebstechschool.com/",
    },
  ],
  about: [
    {
      title: "Our Story",
      link: "#",
    },
    {
      title: "Contact Us",
      link: "mailto:info@ebstechschool.com",
    },
    {
      title: "Terms of Services",
      link: "Privacy Policy",
    },
  ],
  companies: [
    {
      title: "Career",
      link: "#",
    },
    {
      title: "Hire Our Engineers",
      link: "#",
    },
    {
      title: "Partner with Us",
      link: "#",
    },
  ],
  resources: [
    {
      title: "Our Blog",
      link: "#",
    },
    {
      title: "Scholarships",
      link: "#",
    },
  ],
};

const iconStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "20px",
  width: "20px",
  padding: "12px",
  borderRadius: "100%",
  background: "var(--secondary)",
  ".MuiSvgIcon-root": { color: "#fff", fontSize: "16px" },
};

const Title = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.3;
  color: var(--primary) !important;
  text-align: left;
`;

const itemStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "8px",

  a: {
    color: "#85888d !important",

    ":hover": {
      textDecoration: "underline !important",
    },
  },
};

const Links = styled(Link)({
  color: "#85888d !important",
});
