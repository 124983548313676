import React from "react";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const MuiButton = styled(Button)({
  textTransform: "none",
  border: "none",
  lineHeight: 1.5,
  outline: "none",
  borderRadius: "25px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  whiteSpace: "nowrap",
  cursor: "pointer",
  transition: "all 300ms ease",
  fontFamily: "var(--textFont)",
  fontWeight: "600",

  "&.btn-primary": {
    backgroundColor: "var(--primary)",
    color: "#fff",
  },

  "&.btn-primary:hover": {
    opacity: 0.9,
  },

  "&.btn-secondary": {
    backgroundColor: "var(--secondary)",
    color: "#fff",
  },

  "&.btn-secondary:hover": {
    opacity: 0.9,
  },

  "&.btn-tertiary": {
    backgroundImage: "var(--whiteGradient)",
    color: "var(--secondary)",
  },

  "&.btn-text": {
    background: "none",
    justifyContent: "flex-start",
    color: "var(--secondary)",
    transition: "all 300ms ease",
    borderRadius: "none",
  },

  "&.btn-text:hover, .btn-text:hover MuiSvgIcon-root": {
    background: "none",
    color: "#f66503",
    marginLeft: "10px",
  },

  "&.btn-medium": {
    padding: "10px 15px",
    fontSize: "16px",
  },

  "&.btn-large": {
    padding: "15px 30px",
    fontSize: "16px",
  },

  "&.shadow": {
    boxShadow: "var(--shadow)",
  },

  svg: {
    marginLeft: "8px",
  },
});

const ButtonStyles = [
  "btn-primary",
  "btn-primary shadow",
  "btn-secondary",
  "btn-secondary shadow",
  "btn-tertiary",
  "btn-tertiary shadow",
  "btn-text",
];

const ButtonSizes = ["btn-medium", "btn-large"];

export const Buttons = ({
  children,
  type,
  onClick,
  btnVariant,
  btnSize,
  icon,
}) => {
  const BtnStyles = ButtonStyles.includes(btnVariant)
    ? btnVariant
    : ButtonStyles[0];
  const BtnSizes = ButtonSizes.includes(btnSize) ? btnSize : ButtonSizes[0];

  return (
    <MuiButton
      className={`btn ${BtnStyles} ${BtnSizes}`}
      onClick={onClick}
      type={type}
    >
      {children} {icon}
    </MuiButton>
  );
};
