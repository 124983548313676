import styled from "styled-components";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Desktop } from "../Breakpoints/BreakPoints";
import { Buttons } from "components/Button/Buttons";

const HeroContainer = styled("div")`
  background: var(--bgColor);
  padding: 40px 0 5px 0;
  z-index: 0;
  position: relative;
`;

const HeroContent = styled("div")`
  width: 90%;
  margin: 20px auto;
  z-index: 1;
  ${Desktop({
    display: "flex",
    width: "80%",
    justifyContent: "center",
    gap: "5rem",
  })}
`;

const Title = styled("h2")`
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--secondary);
`;
const Text = styled("p")`
  font-size: 1rem;
  font-weight: 400;
  margin: 2rem auto;
  color: var(--primary);
`;

const FlexLeftContent = styled("div")`
  width: 100%;
  @media screen and (min-width: 540px) {
    width: 80%;
  }
  ${Desktop({
    width: "40%",
    marginLeft: "2rem",
  })}
`;

const FlexRightContent = styled("div")`
  display: none;

  ${Desktop({
    display: "flex",
    width: "60%",
    justifyContent: "center",
  })}
`;

const ImageWrap = styled("div")`
  img {
    border-radius: 10px;
    width: 100%;
  }
`;

const Hero = () => {
  return (
    <HeroContainer>
      <HeroContent>
        <FlexLeftContent>
          <Title>
            <span style={{ color: "var(--primary)" }}>We make Teaching</span>{" "}
            Easy{" "}
            <span style={{ color: "var(--primary)" }}>
              {" "}
              for Educators and Learning
            </span>{" "}
            Fun <span style={{ color: "var(--primary)" }}> for Students.</span>
          </Title>

          <Text>
            We deliver high quality coding classes and empower educators with
            innovative tools to deliver world-class learning.
          </Text>

          <Buttons
            onClick={() => window.open("https://sms.clique.ng", "_blank")}
            btnVariant="btn-secondary shadow"
            btnSize="btn-large"
          >
            TRY FOR FREE <ArrowForwardIcon />
          </Buttons>
        </FlexLeftContent>

        <FlexRightContent>
          <ImageWrap>
            <img
              src="https://cliqueng.imgix.net/asset/clique-hero-image.png?w=500"
              alt="hero"
            />
          </ImageWrap>
        </FlexRightContent>
      </HeroContent>
    </HeroContainer>
  );
};

export default Hero;
